import * as React from 'react';
import './MakeCallEmbedded.scss';
import {connect} from 'react-redux';
import {hot} from 'react-hot-loader';
import {ReactSipPhone} from '../../utils/react-sip-phone';
import {addCall} from '../../redux/actions/Contact';
import {User} from "../../models/User";
import {popups, pushPopup} from "../../utils/popups";

interface Props {
	title?: string;
	fetchData?: () => any;
	contactId: string | number;
	phones: any;
	user: User;
}

let currentCallId: string;
let currentCallNumber: string;

const MakeCallEmbedded = (props: Props): JSX.Element => {
	const { contactId, fetchData, user, phones } = props;
	const callEnded = () => {
		window && window.popup.pop();
		pushPopup(popups.MakeCallReview, {
			contactId: contactId,
			onDismiss: fetchData,
			phones: phones,
			user: user,
			callId: currentCallId
		});
	};

	const callStarted = () => {
		addCall({
			id: contactId,
			phone: currentCallNumber,
			cc_id: currentCallId
		}).then((e: any) => {
			fetchData && fetchData();
		});
		window.onbeforeunload = reloadWarning;
	};

	const reloadWarning = () => {
		return 'Call in progress. Are you sure that you want to reload?'
	};

	const onOutgoingCall = (event: any) => {
		const {type ,call_id, to} = event;
		currentCallId = call_id;
		currentCallNumber = to;
		if(type == 'Call Established')
			callStarted();

		if(type == 'User hanged up')
			callEnded();
	};

	const onCallEnded = () => {
		callEnded();
	};

	if(!user.sip_account.sip_username || !user.sip_account.sip_password)
		return (
			<h1>User Phone Credentials Error. Please contact a BednBlue Admin</h1>
		)

	return (
		<ReactSipPhone
			name={'BednBlue'}
			numberList={props.phones}
			isAdmin={false}
			sipCredentials={{
				sipuri: user.sip_account.sip_username + '@call.bnblu.com',
				password: user.sip_account.sip_password
			}}
			sipConfig={{
				websocket: 'wss://call.bnblu.com:8089/ws',
				defaultCountryCode: '',
				video: false,
				iceServers: [
					{ urls: 'stun:stun.l.google.com:19302' },
					{ urls: 'stun:stun1.l.google.com:19302' },
					{ urls: 'stun:stun2.l.google.com:19302' }
				  ],
				noAnswerTimeout: 15000,
				disableIceRestart: true,
				codecs: [
					0,   // ulaw (G.711 PCMU)
					8,   // alaw (G.711 PCMA)
					3,   // gsm
					101, // g719
					18,  // opus
					9,   // g723
					96,  // g729
					100, // g726
					101, // g726aal2
					9    // g722
				  ]
			}}
			phoneConfig={{
				disabledButtons: [user.role_id == 1 ? '' : 'transfer'],
				disabledFeatures: ['conference'],
				defaultDial: '',
				sessionsLimit: 1,
				attendedTransferLimit: 2,
				autoAnswer: false
			}}
			appConfig={{
				mode: '',
				started: true,
				appSize: 'large'
			}}
			onOutgoingCall={onOutgoingCall}
			onCallEnded={onCallEnded}
			needsConfirm={true}
		/>
	);
};

export default hot(module)(
	connect()(MakeCallEmbedded)
);
